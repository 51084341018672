import React from "react";
import clsx from "clsx";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { parseDatasetTag } from "../../utils";
import Tag, { TagStyle } from "../Tag";
import { Disclosure } from "@headlessui/react";
import ReactMarkdown from "react-markdown";
import { StarIcon } from "@heroicons/react/24/solid";
import Tooltip from "../Tooltip";
import { ISingleDataset } from "../../pages/datasets/dataset";

interface DatasetDetailsProps {
  dataset: ISingleDataset;
  translations: { [key: string]: string };
}

interface StrapiElement<T> {
  id: number;
  attributes: T;
}

interface StrapiSingleton<T> {
  data: StrapiElement<T>;
}

interface StrapiArray<T> {
  data: StrapiElement<T>[];
}

interface CatalogDatasetTag {
  value: string;
}

interface StrapiSingleton<T> {
  data;
}
const DatasetDetails: React.FC<DatasetDetailsProps> = ({
  dataset,
}: {
  dataset: {
    name: string;
    shortDescription: string;
    description: string;
    isFeatured: boolean | null;
    historicalData: string;
    pointInTime: boolean | null;
    companiesCovered: number;
    serviceCategory: string;
    serviceSubcategory: string;
    deliveryOptions: string[];
    updateFrequencies: string[];
    assetClasses: string[];
    sectors: string[];
    geographies: string[];
  };
}) => {
  const tags = Array.from(
    new Set([dataset.serviceCategory, dataset.serviceSubcategory]),
  ).filter((tag) => tag != null);

  return (
    <div className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 border-4 bg-white border-gray-200/90 border-t-blue shadow-lg font-roboto">
      <div
        className={clsx(
          "my-3 pl-2 sm:pl-4 pt-3 pb-2",
          dataset.isFeatured ? "pr-2 sm:pr-4" : "pr-8 sm:pr-12",
        )}
      >
        <div className="flex item-center">
          <h1 className="flex flex-1 text-2xl md:text-3xl lg:text-4xl font-semibold">
            {dataset.name}
          </h1>
          {dataset.isFeatured && (
            <div className="flex items-center ml-2 md:ml-3">
              <Tooltip text="Featured" width="90px">
                <StarIcon className="h-6 text-orange cursor-pointer" />
              </Tooltip>
            </div>
          )}
        </div>

        <div className="mt-2 md:mt-3 xl:mt-4">
          <ul className="flex flex-wrap">
            {tags.map((tag, index) => (
              <li
                key={`${index}-${tag}`}
                className="mr-2 xs:mr-3 sm:mr-4 py-0.5"
              >
                <Tag id={`${index}`} name={tag} tagStyle={TagStyle.SQUARE} />
              </li>
            ))}
          </ul>
        </div>

        <div className="text-blue mt-2 md:mt-3 xl:mt-4">
          <a
            href={`mailto:dse@bamelevate.com`}
            className="flex gap-x-1 font-bebas-neue text-lg"
          >
            <div>
              <span className="sr-only">Email</span>
              <EnvelopeIcon className="w-6 h-6 mt-px" />
            </div>
            CONTACT
          </a>
        </div>
      </div>
      <div className="mt-1 xl:text-lg">
        <Disclosure defaultOpen as="div">
          {({ open = true }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                  Summary
                </h2>
                <div className="flex">
                  <span className="mt-1 ml-6 flex h-6 items-center">
                    {open ? (
                      <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel
                as="div"
                className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
              >
                <h3 className="font-semibold text-600">
                  {dataset.shortDescription}
                </h3>
                <ReactMarkdown className="my-4 leading-relaxed">
                  {dataset.description || ""}
                </ReactMarkdown>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure defaultOpen as="div">
          {({ open = true }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                  At-A-Glance
                </h2>
                <div className="flex">
                  <span className="mt-1 ml-6 flex h-6 items-center">
                    {open ? (
                      <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel
                as="div"
                className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-loose"
              >
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">History Start</div>
                  <div>
                    <div>{dataset.historicalData}</div>
                  </div>
                </div>
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Update Frequency</div>
                  <div>{(dataset.updateFrequencies ?? []).join(", ")}</div>
                </div>
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Point in Time</div>
                  <div>
                    {dataset.pointInTime ? <div>Yes</div> : <div>No</div>}
                  </div>
                </div>
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Coverage</div>
                  <div>
                    <div>
                      {dataset.companiesCovered
                        ? Number(dataset.companiesCovered).toLocaleString()
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="pt-2 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Geography</div>
                  <div>{(dataset.geographies ?? []).join(", ")}</div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default DatasetDetails;
