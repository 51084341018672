import React, { useEffect, useState } from "react";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import { navigate } from "gatsby";
import { IDataset, IDatasetDocument } from "../../types/datasets";
import Spinner from "../../components/Spinner";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";

import { ChevronRightIcon } from "@heroicons/react/24/solid";
import DatasetDetails from "../../components/DatasetDetails";

export interface ISingleDataset extends IDataset {
  historicalData: string;
  updateFrequency: string;
  pointInTime: string;
  publicCompaniesCovered: string;
  documentsList: IDatasetDocument[];
  detailedServiceDescription: string;
}

interface StrapiWrapper<T> {
  id: number;
  attributes: T;
}

interface StrapiUnit<T> {
  data: StrapiWrapper<T>;
}

interface StrapiArray<T> {
  data: StrapiWrapper<T>[];
}

interface CatalogDatasetTag {
  value: string;
}

interface Vendor {
  name: string;
}

interface CatalogDataset {
  name: string;
  shortDescription: string;
  description: string;
  isFeatured: boolean | null;
  historicalData: string;
  pointInTime: boolean | null;
  publicCompaniesCovered: number;
  vendor: StrapiUnit<Vendor>;
  serviceCategory: StrapiUnit<CatalogDatasetTag>;
  serviceSubcategory: StrapiUnit<CatalogDatasetTag>;
  deliveryOptions: StrapiArray<CatalogDatasetTag>;
  updateFrequencies: StrapiArray<CatalogDatasetTag>;
  assetClasses: StrapiArray<CatalogDatasetTag>;
  sectors: StrapiArray<CatalogDatasetTag>;
  geographies: StrapiArray<CatalogDatasetTag>;
}

const Dataset: React.FC = ({ params: { id } }: { params: { id: number } }) => {
  const { accessToken, clearIdentity } = useAuthContext();

  const [dataset, setDataset] = useState<CatalogDataset>(null);
  const [vendor, setVendor] = useState<Vendor>(null);

  const { data, isLoading, error } = useNorthstar(
    `/api/catalog-datasets/${id}`,
    accessToken,
    {
      method: "get",
      params: {
        fields: [
          "name",
          "shortDescription",
          "description",
          "isFeatured",
          "historicalData",
          "pointInTime",
          "companiesCovered",
        ],
        populate: [
          "serviceCategory",
          "serviceSubcategory",
          "vendor",
          "deliveryOptions",
          "updateFrequencies",
          "assetClasses",
          "sectors",
          "geographies",
        ],
      },
    },
  );

  useEffect(() => {
    if (data) {
      setDataset(data.data.attributes);
      setVendor(data.data.attributes?.vendor);
    }
  }, [data?.data?.id]);

  useEffect(() => {
    let ignore = true;

    if (error?.status === 401 && !ignore) clearIdentity();
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  return (
    <NorthstarPageLayout
      title={
        dataset
          ? [dataset.vendor, dataset.name].filter((x) => x).join(": ")
          : ""
      }
    >
      <div className="w-full flex justify-center">
        <Spinner active={isLoading} />
        {dataset && !isLoading && (
          <div className="max-w-[1200px]">
            <nav
              className="flex mt-3 font-bebas-neue tracking-wide text-lg"
              aria-label="Breadcrumb"
            >
              <div className="flex item-center space-x-4">
                <div
                  onClick={() => navigate("/datasets")}
                  className="text-blue hover:text-bright-blue cursor-pointer"
                >
                  All Datasets
                </div>
                <ChevronRightIcon
                  className="mt-2 h-3 w-3 flex-shrink-0"
                  aria-hidden="true"
                />
                <div className="text-orange">{vendor?.name}</div>
              </div>
            </nav>
            <DatasetDetails dataset={dataset} />
          </div>
        )}
      </div>
    </NorthstarPageLayout>
  );
};

export default Dataset;
